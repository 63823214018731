import { SelectOption } from "./SelectOption";

export const standardsRatingOptions: SelectOption[] = [
  {
    key: "EN12900",
    value: "EN12900",
  },
  {
    key: "DA12",
    value: "DA12",
  },
  {
    key: "EN13215",
    value: "EN13215",
  },
  {
    key: "Custom",
    value: "Custom",
  },
];
